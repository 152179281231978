import { cleanString } from "./productUtil"
import { getFrontendUrl } from "../../config"
import { AddressType } from "@social-supermarket/social-supermarket-components"
import sbjs from "sourcebuster"

export const isRunTime = (): boolean => {
  return typeof window !== "undefined"
}

export const isOrderSuccessPage = (): boolean => {
  return typeof window !== "undefined" && window.location.href.includes("order-success")
}

export const isUndefined = (object): boolean => {
  return typeof object !== "undefined"
}

export const equalsIgnoreCase = (s1, s2) => {
  if (!s1 && !s2) {
    return true
  }

  if ((!s1 && s2) || (!s2 && s1)) {
    return false
  }
  return cleanString(s1) === cleanString(s2)
}

export const getImpactSlugs = impactString => {
  return impactString
    ? Array.isArray(impactString)
      ? impactString
      : impactString.split(",").map(slug => slug.trim())
    : []
}

export const stripLinks = (text: string): string => {
  let result = text.replace(/(<a([^>]+)>)/gi, "")
  result.replace("</a>", "")
  return result
}

export const isANumber = (thing): boolean => {
  return !isNotANumber(thing)
}
export const isNotANumber = (thing): boolean => {
  return isNaN(parseFloat(thing))
}

export const lockScroll = (lock: boolean): void => {
  document.documentElement.style.overflowY = lock ? "hidden" : "auto"
  document.documentElement.style.height = lock ? "100%" : "100%"
}

export const uniq = a => {
  let seen = {}
  return a.filter(item => {
    return seen.hasOwnProperty(item) ? false : (seen[item] = true)
  })
}

export const getOpenGiftSelectionUrl = (key: string) => {
  // return `${getFrontendUrl()}/gift-selection/${key}`
  return `${getFrontendUrl()}/gift-selector?key=${key}`
}

export const getPrivateGiftSelectionUrl = () => {
  // return `${getFrontendUrl()}/select-gift/`
  return `${getFrontendUrl()}/private-gift-selector?key=`
}

export const nl2br = (text: string): string => {
  return text ? `${text}`.replace(/(?:\r\n|\r|\n)/g, "<br/>") : ""
}

export const getSourceTrackingInfo = () => {
  if (sbjs?.get) {
    const { current, first } = sbjs.get
    return {
      first: {
        utmSource: first.src,
        utmMedium: first.mdm,
        utmCampaign: first.cmp,
        utmContent: first.cnt,
        utmTerm: first.trm,
      },
      current: {
        utmSource: current.src,
        utmMedium: current.mdm,
        utmCampaign: current.cmp,
        utmContent: current.cnt,
        utmTerm: current.trm,
      },
    }
  } else {
    return { first: {}, current: {} }
  }
}

export const arraysHaveOverlap = (arr1: string[], arr2: string[]) => {
  const set2 = new Set(arr2)

  for (let element of arr1) {
    if (set2.has(element)) {
      return true
    }
  }
  return false
}
