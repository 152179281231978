exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-clockwise-tsx": () => import("./../../../src/pages/clockwise.tsx" /* webpackChunkName: "component---src-pages-clockwise-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-drax-tsx": () => import("./../../../src/pages/drax.tsx" /* webpackChunkName: "component---src-pages-drax-tsx" */),
  "component---src-pages-gift-selector-tsx": () => import("./../../../src/pages/gift-selector.tsx" /* webpackChunkName: "component---src-pages-gift-selector-tsx" */),
  "component---src-pages-gifting-checkout-tsx": () => import("./../../../src/pages/gifting/checkout.tsx" /* webpackChunkName: "component---src-pages-gifting-checkout-tsx" */),
  "component---src-pages-gifting-gift-list-tsx": () => import("./../../../src/pages/gifting/gift-list.tsx" /* webpackChunkName: "component---src-pages-gifting-gift-list-tsx" */),
  "component---src-pages-gifting-index-tsx": () => import("./../../../src/pages/gifting/index.tsx" /* webpackChunkName: "component---src-pages-gifting-index-tsx" */),
  "component---src-pages-gifting-update-options-tsx": () => import("./../../../src/pages/gifting/update-options.tsx" /* webpackChunkName: "component---src-pages-gifting-update-options-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-impact-tsx": () => import("./../../../src/pages/impact.tsx" /* webpackChunkName: "component---src-pages-impact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investec-tsx": () => import("./../../../src/pages/investec.tsx" /* webpackChunkName: "component---src-pages-investec-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password-reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-private-gift-selector-tsx": () => import("./../../../src/pages/private-gift-selector.tsx" /* webpackChunkName: "component---src-pages-private-gift-selector-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-report-tsx": () => import("./../../../src/pages/report.tsx" /* webpackChunkName: "component---src-pages-report-tsx" */),
  "component---src-pages-slalom-tsx": () => import("./../../../src/pages/slalom.tsx" /* webpackChunkName: "component---src-pages-slalom-tsx" */),
  "component---src-pages-softcat-tsx": () => import("./../../../src/pages/softcat.tsx" /* webpackChunkName: "component---src-pages-softcat-tsx" */),
  "component---src-pages-supplies-checkout-tsx": () => import("./../../../src/pages/supplies/checkout.tsx" /* webpackChunkName: "component---src-pages-supplies-checkout-tsx" */),
  "component---src-pages-supplies-index-tsx": () => import("./../../../src/pages/supplies/index.tsx" /* webpackChunkName: "component---src-pages-supplies-index-tsx" */),
  "component---src-templates-gift-selection-template-js": () => import("./../../../src/templates/GiftSelectionTemplate.js" /* webpackChunkName: "component---src-templates-gift-selection-template-js" */),
  "component---src-templates-shareable-gift-selection-template-js": () => import("./../../../src/templates/ShareableGiftSelectionTemplate.js" /* webpackChunkName: "component---src-templates-shareable-gift-selection-template-js" */)
}

