import FavoriteType from "./FavoriteType"
import { ProductType } from "@social-supermarket/social-supermarket-components"

export type FavoritesContextType = {
  favoriteProducts: FavoriteType[]
  toggleFavorite: (product: ProductType, category: string) => Promise<void>
  addFavorite: (product: ProductType, category: string) => Promise<void>
  removeFavorite: (product: ProductType, category: string) => Promise<void>
}

export const DEFAULT_FAVORITES_CONTEXT_TYPE: FavoritesContextType = {
  favoriteProducts: [],
  toggleFavorite: () => null,
  addFavorite: () => null,
  removeFavorite: () => null,
}
