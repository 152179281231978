import ProductSelectionType from "../pages/gift/choice/ProductSelectionType"
import OrderType from "./OrderType"
import PaymentType from "./PaymentType"
import BasketItemType from "../pages/gift/choice/BasketItemType"
import BasketShippingType from "../pages/gift/choice/BasketShippingType"
import PlatformRecipientType from "./PlatformRecipientType"

export type GiftListContextType = {
  choices: ProductSelectionType[]
  addChoices: (choices: ProductSelectionType[]) => void
  removeChoice: (choice: ProductSelectionType) => void
  cancel: () => void
  allGiftLists: OrderType[]
  updateGiftList: (list: OrderType) => void
  isLoadingGiftPages: boolean
}
export const DEFAULT_GIFT_LIST_CONTEXT_TYPE: GiftListContextType = {
  choices: [],
  addChoices: choice => null,
  removeChoice: choice => null,
  cancel: () => null,
  allGiftLists: [],
  updateGiftList: () => null,
  isLoadingGiftPages: true,
}
