import * as React from "react"
import LocationProvider from "./LocationProvider"
import FavoritesProvider from "./FavoritesProvider"
import GiftProvider from "./GiftProvider"
import GiftListProvider from "./GiftListProvider"
import { Context, createContext, useState } from "react"
import styled from "styled-components"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import { getConfig } from "../../config"
import GiftOrderProvider from "./GiftOrderProvider"
import SuppliesOrderProvider from "./SuppliesOrderProvider"
import {
  ThemeProvider,
  ThemeType,
  LoginProvider,
  colors,
  Portal,
} from "@social-supermarket/social-supermarket-components"

const stripePromise = loadStripe(getConfig().stripeKey)

const GrowlNotice = styled.div`
  font-family: Helvetica, Verdana, sans-serif;
  padding: ${({ show }) => (show ? "10px 30px" : 0)};
  border-radius: 4px;
  opacity: ${({ show }) => (show ? 0.7 : 0)};
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  color: #fff;
  background-color: ${colors.primaryLight};
  position: fixed;
  right: 10px;
  bottom: 10px;
  line-height: 60px;
  pointer-events: none;
  z-index: ${({ show }) => (show ? 9999 : -100)};
`

const theme: ThemeType = {
  primary: colors.primaryDark,
  controls: colors.primaryDark,
  invertedControls: colors.primaryLight,
}

interface GlobalContextType {
  showGrowl: (text: string) => void
}

export const GlobalContext: Context<GlobalContextType> = createContext<GlobalContextType>({
  showGrowl: () => {},
})

const GlobalProvider = ({ children }) => {
  const [isGrowlShown, setIsGrowlShown] = useState<boolean>(false)
  const [growlText, setGrowlText] = useState<string>("")

  const showGrowl = (text: string) => {
    setGrowlText(text)
    setIsGrowlShown(true)
    setTimeout(() => {
      setIsGrowlShown(false)
    }, 2000)
  }

  return (
    <Elements stripe={stripePromise}>
      <GlobalContext.Provider value={{ showGrowl }}>
        <ThemeProvider defaultTheme={theme}>
          <LocationProvider>
            <LoginProvider baseUrl={getConfig().baseUrl}>
              <GiftOrderProvider>
                <SuppliesOrderProvider>
                  <GiftListProvider>
                    <FavoritesProvider>
                      <GiftProvider>{children}</GiftProvider>
                    </FavoritesProvider>
                  </GiftListProvider>
                </SuppliesOrderProvider>
              </GiftOrderProvider>
            </LoginProvider>
          </LocationProvider>
          <Portal>
            <GrowlNotice show={isGrowlShown}>{growlText}</GrowlNotice>
          </Portal>
        </ThemeProvider>
      </GlobalContext.Provider>
    </Elements>
  )
}

export default GlobalProvider
